import * as css from "./button.module.scss"
import * as React from "react"

export function LinkButton(props: {
  url: string
  type?: "primary" | "secondary" | "border"
  children?: any
  className?: string
  blank?: boolean
  onClick?: (e) => any
}) {
  return (
    <a
      className={`${css.btn} ${props.className || ""} ${
        css[props.type || "secondary"]
      }`}
      href={props.url}
      target={props.blank ? "_blank" : ""}
      onClick={e => props.onClick && props.onClick(e)}
    >
      {props.children}
    </a>
  )
}
